//import directives from './directives';
//import filters from './filters';
//import * as d3 from 'd3';
//import moment from 'moment';

export function setLocalValue(name, value) {
  localStorage.setItem(name, JSON.stringify(value));
}

export function getLocalValue(name) {
  var value = localStorage.getItem(name);
  if (value) return JSON.parse(value);
}

export function removeLocalValue(name) {
  localStorage.removeItem(name);
}

// dig for a property inside on object (o) using an array path (p)
// d is the default and its returned if nothing else is found
export const get = (p, o, d = null) =>
  p.reduce((xs, x) => (xs && typeof(xs[x])!==undefined) ? xs[x] : d, o);
