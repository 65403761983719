//import Vue from 'vue';

import {
  fetch,
  post,
  remove,
  put,
  //patch,
} from '@/api';

import {
  //assign,
  get,
  setLocalValue,
  getLocalValue,
  removeLocalValue,
} from '../utils';

//import ls from '../../Global/indexedDBService';

const SET_LOADED = 'SET_LOADED';
const SET_INITIALIZED = 'SET_INITIALIZED';

const SET_RECORDS = 'SET_RECORDS';
const SET_INPUTS = 'SET_INPUTS';
const SET_REPORTS = 'SET_REPORTS';
const SET_SEARCHES = 'SET_SEARCHES';
const SET_FILTERS = 'SET_FILTERS';
const SET_META = 'SET_META';
const SET_RECORD_DATA = 'SET_RECORD_DATA';
const SET_LOOKUP_DATA = 'SET_LOOKUP_DATA';
const SET_TABLE_DATA = 'SET_TABLE_DATA';
const CLEAR_LOOKUP_DATA = 'CLEAR_LOOKUP_DATA';
const MERGE_RECORDS = 'MERGE_RECORDS';
const REMOVE_RECORDS = 'REMOVE_RECORDS';
const SORT_RECORDS = 'SORT_RECORDS';
const SET_COLLECTION = 'SET_COLLECTION';
const APPEND_RECORDS = 'APPEND_RECORDS';
const SET_UNIQUEID = 'SET_UNIQUEID';

const UPDATE_RECORD = 'UPDATE_RECORD';
const MODIFY_RECORD = 'MODIFY_RECORD';
const REMOVE_RECORD = 'REMOVE_RECORD';
const ADD_RECORD = 'ADD_RECORD';

const ADD_RECENTLY_ADDED = 'ADD_RECENTLY_ADDED';
const SET_RECENTLY_ADDED = 'SET_RECENTLY_ADDED';
const CLEAR_RECENTLY_ADDED = 'CLEAR_RECENTLY_ADDED';

//const ADD_COLLECTION_RECORD = 'ADD_COLLECTION_RECORD';
const UPDATE_COLLECTION_RECORD = 'UPDATE_COLLECTION_RECORD';
//const REMOVE_COLLECTION_RECORD = 'REMOVE_COLLECTION_RECORD';
const UPDATE_COLLECTION_RECORD_FIELD = 'UPDATE_COLLECTION_RECORD_FIELD';



//const SUBMIT_MESSAGE = 'PageMessages/SUBMIT_MESSAGE';
const SUBMIT_MESSAGE = 'PageMessages/submitMessage';

const TOGGLE_ITEM = 'TOGGLE_ITEM';
const EXPAND_ITEM = 'EXPAND_ITEM';
//const COLLAPSE_ITEM = 'COLLAPSE_ITEM';
const EXPAND_ITEMS = 'EXPAND_ITEMS';
//const COLLAPSE_ITEMS = 'COLLAPSE_ITEMS';

const TOGGLE_SELECTED = 'TOGGLE_SELECTED';
const SELECT_ITEM = 'SELECT_ITEM';
const UNSELECT_ITEM = 'UNSELECT_ITEM';
const SELECT_ITEMS = 'SELECT_ITEMS';
const UNSELECT_ITEMS = 'UNSELECT_ITEMS';

export const initialState = {
  uniqueId: 'uid', // string representing unique id
  record: null, // singular
  collection: null, // name of the collection
  form: 'CollectionForm',
  initialized: false,
  loaded: false,
  objectId: null, // what is the internal uid for this object?
  temporary: false, // can this table be removed??
  tables: [], // params for any support tables for this collection
  inputs: [], // these are the inputs needed to edit/add
  order: {}, // should be key => direction
  // what views are available for this collection
  // examples include rows, cards, table, map
  meta: {},
  fields: [],
  // filter: {}, // filter used for getting data
  lookupData: [], // results of the latest lookup search
  data: [], // actual data
//  keys: {}, // storing who belongs to what
  records: {}, // Detail data, keyed by uniquid
  rawRecords: {}, // Raw record data, keyed by unique
  recentlyAdded: [], // for any new record that was added during this `session`
  history: [], // future ??
  expanded: [],
  selected: [],
  searches: [], // simple saved searches
  reports: [], // more complex reports
  filters: {
    inputs: [],
    values: {}
  }, // form filters data by current search
  includeHomePage: true,
  sortByOptions: [
    { label: 'Label', uid: 'label' },
  ],
  groupByOptions: [],
  viewAsOptions: [
    { label: 'swimlanes', uid: 'swimlanes' },
    { label: 'Map', uid: 'map' },
    { label: 'Checklist', uid: 'checklist' },
    { label: 'Table', uid: 'table' },
    { label: 'Rows', uid: 'rows' },
    { label: 'Cards', uid: 'cards' },
  ],
  addedMessageType: 'record-updated',
  api: {
    get: 'details/:id',
    put: 'save/:id',
    post: 'saveas',
    remove: 'remove/:id',
    unlink: 'unlink/:id',
    search: 'search2',
    record: 'record/:id',
  }
};

export const getters = {
  count: state => state.data.length,
  label: state => state.meta.label || state.label || state.collection,
  api: (state) => (verb, id) => {
    const uri = `${state.collection}/${state.api[verb]}`;
    return id ? uri.replace(':id', id) : uri.replace('/:id', '');
  },
  data: state => {
    return state.data.map( d => {
      return {
          ...d,
        expanded: state.expanded.includes(d.uid),
        selected: state.selected.includes(d.uid)
      };
    });
  },
  reports: state => {
    const r = state.reports.map(d => ({ ...d, key: `r${d.uid}`, type: 'report'}));
    const s = state.searches.map(d => ({ ...d, key: `s${d.uid}`, type: 'search'}));
    return [...s, ...r];
  }
};

export const mutations = {
  [SET_LOADED](state, value) {
    state.loaded = value;
  },
  [SET_INITIALIZED](state, value) {
    state.initialized = value;
  },
  [SET_UNIQUEID](state, value) {
    state.uniqueId = value;
  },
  [SET_FILTERS](state, { filters, values }) {
    state.filters = {
      inputs: filters ? Object.values(filters) : [],
      values
    };
  },
  [SET_RECORDS](state, values) {
    if (values) {
      state.data = values.data || values || [];
    }
  },
  [SET_RECORD_DATA](state, data) {
    if(data) {
      let uid = data[state.uniqueId];
      state.rawRecords = { ...state.rawRecords, [uid]: data };
      //Vue.set(state.rawRecords, data[state.uniqueId], data)
    }
  },
  [SET_LOOKUP_DATA](state, values) {
    state.lookupData = values || [];
  },
  [SET_TABLE_DATA](state, { table, data }) {
    state[table] = data;
  },
  [CLEAR_LOOKUP_DATA](state) {
    state.lookupData = [];
  },
  [SET_INPUTS](state, inputs) {
    // console.log('inputs', inputs)
    if(inputs) state.inputs = inputs;
  },
  [SET_REPORTS](state, reports) {
    state.reports = reports || [];
  },
  [SET_SEARCHES](state, searches) {
    state.searches = searches || [];
  },
  [SORT_RECORDS](state, { key }) {
    var field, fld, dir = 'asc';
    if(key !== null && typeof(key) === 'string') {
      fld = get(['order', key], state);
      if(fld) {
        dir = (fld == 'asc') ? 'desc' : 'asc';
        field = { [key]: dir };
      } else {
        field = { [key]: 'asc' };
      }
    }

    state.order = field;
    let fn = state.rowSortingFunctions && state.rowSortingFunctions[key];
    //console.log('sorting', dir, key, fld, field, fn);
    if(!fn) {
      fn = dir => {
        const adj = dir == 'asc' ? 1 : -1;
        return (a,b) => {
          let al = a[key];
          let bl = b[key];
          if(typeof(al) == 'object') {
            // try and sort it by something
            al = al.label || Object.values(al)[0];
            bl = bl.label || Object.values(bl)[0];
          }
          if(!al) al = '';
          if(!bl) bl = '';
          //console.log('sorting', al, bl, al > bl, al > bl ? 1*adj : -1*adj);
          return al.toString().toLocaleLowerCase() > bl.toString().toLocaleLowerCase() ? 1*adj : -1*adj;
        };
      };
    }
    state.data.sort(fn(dir));
  },
  [REMOVE_RECORDS](state, ids) {
    const pkey = state.uniqueId;
    const data = state.data.filter( d => !ids.includes(d[pkey]));
    state.data = data;
    //console.log('remove records', state.collection, pkey, ids.length, data.length, ids);
  },
  [MERGE_RECORDS](state, values) {
    if(!values) return;
    // quit merging for now
    // state.data = values.data || values || [];
    const mStart = `${state.collection}-get-start`,
          mFiltered = `${state.collection}-get-filtered`,
          mFound = `${state.collection}-get-found`,
          mMerged = `${state.collection}-get-merged`;
    window.performance.mark(mStart);

    //const n = state.data.length;
    //const t0 = performance.now();
    if (state.data.length === 0) {
      state.data = values.data || values || [];
      //console.log('replaced records', performance.now()-t0, state.record, state.data.length);
    } else {
      // first we figure out which ones we already have
      // then we set those aside and merge the rest
      // then we can loop through the
      //console.log('Merging records', state.record, performance.now()-t0);
      // merge willy
      const uid = state.uniqueId;
      //console.log('merging with', uid);
      const ids = values.map( d => d[uid]);
      // filter out the duplicates
      const data = state.data.filter( d => !ids.includes(d[uid]));
      window.performance.mark(mFiltered);
      //console.log('filtered', state.record, performance.now()-t0);

      // but merge the duplicate data, this will make sure we have all the required data
      // merging individual records takes way too long
      // values = values.map(x => Object.assign(state.data.find(y => y[uid] == x[uid])||{}, x));
      window.performance.mark(mFound);
      //console.log('Mapped', state.record, performance.now()-t0, values.length, state.data.length, data.length);
      // and put it all back
      state.data = [...data, ...values ];
      window.performance.mark(mMerged);
      //console.log('Merge done', state.record, performance.now()-t0, state.record, state.uniqueId, ids, data, state.data);

      window.performance.measure(`${state.collection}: start->filtered collection data`, mStart, mFiltered);
      window.performance.measure(`${state.collection}: filtered->found collection data`, mFiltered, mFound);
      window.performance.measure(`${state.collection}: found->merged collection data`, mFound, mMerged);
    }
  },
  [SET_COLLECTION](state, col) {
    //console.log('set collection', state.collection, col)
    if (col.meta) {
      state.objectId = col.meta.objectId;
      state.meta = col.meta;
    }
    if (col.fields) state.fields = col.fields || [];
    if (col.data) state.data = col.data || [];

    state.loaded = true;
  },
  [SET_META](state, value) {
    state.objectId = value.objectId || value.object_id;
    state.meta = value;
  },
  [APPEND_RECORDS](state, values) {
    if (values) state.data = state.data.concat(values);
  },
  [UPDATE_RECORD](state, value) {
    if(!value) return;
    //    const uid = `u${value[state.uniqueId]}`;
    // having an issue with feature_id vs issue_feature_id
    // and with deliverable_id vs project_deliverable_id (with deliverables)
    // 2020-06-09 : I cant use the uid here and then the uniqueId below
    // as it is causing errors (e.g. uniqueId = task_id, uid = project_task_id)
    // 2020-06-15 : id will HAVE to be the fkey and not the link id
    // which means we have to make sure that is the case everywhere
    // find the right record
    // use the id as a backup??
    let fid = value[state.uniqueId]||value['id'];
    let row = Object.values(state.records).find(d => d[state.uniqueId] == fid);
    let id = value['uid']||value['id'];
    //let id = value[state.uniqueId];
    let uid = `u${id}`;

    //console.log('wtf', state.uniqueId, fid, row, id, uid, state.records);
    // console.log('update records', state.uniqueId, row, fid, id, uid, state.records);
    //if(idx>=0) {
    //  row = state.records[idx];
    //  console.log('found a row', row);
      // have to use the set method to get a refresh
      //Vue.set(state.records, uid, value);
   // }
    //console.log('update records', state.uniqueId, state.collection, idx, id, uid, fid);
    state.records = { ...state.records, [uid]: { ...row, ...value } };
    // now find the record in the collection data
    // this does not work the way that I want it to
    // I can fix this until
    //if(state.uniqueId === 'uid') {
    let idx = state.data.findIndex( d => d[state.uniqueId] == fid);
    if(idx>=0) {
      row = state.data[idx];
      //console.log('replacing collection data', state.uniqueId, idx, row, value);
      state.data.splice(idx, 1, { ...row, ...value });
    }
  },
  // [UPDATE_RECORD](state, value) {
  //   console.log('update records', state.uniqueId, state.collection, value);
  //   if(!value) return;
  //   //    const uid = `u${value[state.uniqueId]}`;
  //   // having an issue with feature_id vs issue_feature_id
  //   // and with deliverable_id vs project_deliverable_id (with deliverables)
  //   // 2020-06-09 : I cant use the uid here and then the uniqueId below
  //   // as it is causing errors (e.g. uniqueId = task_id, uid = project_task_id)
  //   // 2020-06-15 : id will HAVE to be the fkey and not the link id
  //   // which means we have to make sure that is the case everywhere
  //   const id = value['uid']||value['id'];
  //   const uid = `u${id}`;
  //   var row = state.records[uid];
  //   // have to use the set method to get a refresh
  //   //Vue.set(state.records, uid, value);
  //   state.records = { ...state.records, [uid]: { ...row, ...value } };
  //   // now find the record in the collection data
  //   // this does not work the way that I want it to
  //   // I can fix this until
  //   if(state.uniqueId === 'uid') {
  //     let idx = state.data.findIndex( d => d[state.uniqueId] == id);
  //     if(idx>=0) {
  //       row = state.data[idx];
  //       //console.log('replacing collection data', state.uniqueId, idx, row, value);
  //       row = { ...row, ...value };
  //       state.data.splice(idx, 1, row);
  //     }
  //   } else {
  //     console.log(row);
  //   }
  // },
  [SET_RECENTLY_ADDED](state, ids) {
    if(ids && ids.length) {
      state.recentlyAdded = ids;
    }
  },
  [ADD_RECENTLY_ADDED](state, id) {
    state.recentlyAdded.push(id);
    setLocalValue(`${state.collection}RecentlyAdded`, state.recentlyAdded);
  },
  [CLEAR_RECENTLY_ADDED](state) {
    state.recentlyAdded = [];
    removeLocalValue(`${state.collection}RecentlyAdded`);
  },
  [ADD_RECORD](state, value) {
    // console.log('COLLECTION: ADD_RECORD');
    state.data = [...state.data, value];
  },
  [UPDATE_COLLECTION_RECORD](state, value) {
    if(!value) return;
    const pkey = 'uid';
    const uid = value[pkey];
    const idx = state.data.findIndex(d => parseInt(d[pkey]) === uid);
    if (idx >= 0) {
      state.data.splice(idx, 1, value);
    } else {
      state.data = [...state.data, value ];
    }
  },
  [UPDATE_COLLECTION_RECORD_FIELD](state, data) {
    if(!data) return;
    const pkey = 'uid';
    const uid = data[pkey];
    const idx = state.data.findIndex(d => parseInt(d[pkey]) === uid);
    if (idx >= 0) {
      let record = state.data[idx];
      state.data.splice(idx, 1, { ...record, ...data });
    }
  },
  [REMOVE_RECORD](state, { id }) {
    // console.log('COLLECTION: ADD_RECORD');
    const pkey = 'uid';//state.uniqueId;
    let idx = state.data.findIndex(obj => parseInt(obj[pkey]) === id);
    if(idx<0) {
      // 2 April 2020
      // I am not sure why I was using uid above, but it fails when I am asking
      // for the collection to delete a task when the tasks were retrieved using the
      // the parent task id (e.g project_task_id)
      // moving forward everything here should reference the collection id
      // and so this is a hack to make sure it works for tasks
      idx = state.data.findIndex(obj => parseInt(obj[state.uniqueId]) === id);
    }
    //console.log('trying to remove a record', id, pkey, idx, state.uniqueId);
    if (idx >= 0) state.data.splice(idx, 1);
  },
  [MODIFY_RECORD](state, { id, data }) {
    const pkey = state.uniqueId;
    const idx = state.data.findIndex(obj => parseInt(obj[pkey]) === id);
    console.log('COLLECTION: Modify record', id, idx);
    if (idx >= 0) state.data[idx] = Object.assign(state.data[idx], data);
  },
  // this method of toggling seems to recreate all components based on the data
  // which means that the expanded watcher is not being triggered
  // for now we can put the expanded watcher in the create method
  [TOGGLE_ITEM](state, { id }) {
    const idx = state.expanded.findIndex(d => d === id);
    if(idx>=0){
      state.expanded.splice(idx, 1);
    } else {
      state.expanded.push(id);
    }
    console.log('toggle item',id, idx);
  },
  [EXPAND_ITEM](state, { id }) {
    const idx = state.expanded.findIndex(d => d === id);
    if(idx < 0) {
      state.expanded.push(id);
    }
  },
  [EXPAND_ITEMS](state, ids) {
    for(let i = 0; i<state.data.length; i++){
      let { uid } = state.data[i];
      if(!ids || ids.includes(uid)) {
        state.data[i].expanded = true;
        state.data.splice(i, 1, state.data[i]);
      }
    }
  },
  [TOGGLE_SELECTED](state, { id }) {
    const idx = state.selected.findIndex(d => d === id);
    if(idx >= 0){
      state.selected.splice(idx,1);
    } else {
      state.selected.push(id);
    }
    //console.log('toggle selected', state.record, id, idx)
  },
  [SELECT_ITEM](state, { id }) {
    if(!state.selected.includes(id)) {
      state.selected.push(id);
    }
  },
  [UNSELECT_ITEM](state, { id}) {
    const idx = state.data.findIndex(d => d.uid === id);
    if(idx >= 0) {
      state.data[idx].selected = false;
      state.data.splice(idx, 1, state.data[idx]);
    }
  },
  [SELECT_ITEMS](state, ids) {
    for(let i = 0; i<state.data.length; i++){
      let { uid } = state.data[i];
      if(!ids || ids.includes(uid)) {
        state.data[i].selected = true;
        state.data.splice(i, 1, state.data[i]);
      }
    }
  },
  [UNSELECT_ITEMS](state, ids) {
    for(let i = 0; i<state.data.length; i++){
      let { uid } = state.data[i];
      if(!ids || ids.includes(uid)) {
        state.data[i].selected = false;
        state.data.splice(i, 1, state.data[i]);
      }
    }
  },
};

export const actions = {
  initialize({ state, commit }) {
    console.log('initializeing collection', state.collection);
    if(!state.initialized) {
      const ids = getLocalValue(`${state.collection}RecentlyAdded`);
      commit(SET_RECENTLY_ADDED, ids);
      commit(SET_INITIALIZED, ids);
      return true;
    } else {
      return true;
    }
  },
  setCollectionInfo(context, { uniqueId }) {
    context.commit(SET_UNIQUEID, uniqueId);
  },
  load({ dispatch }, args = {}) {
    console.log('loading data', args);
    return dispatch('getCollectionData', args);

    //const url = `${state.collection}/search`;
    //return fetch(url, args)
    //  .then( res => {
    //    commit(SET_COLLECTION, res);
    //    return res;
    //  });
  },
  destroy() {
    // placeholder for now
    return new Promise(resolve=>resolve(true));
  },
  // this is to be used when not part of a parent module
  // but sometimes this is called and the collection parameter is passed
  // so we want to create a sink for it
  getCollectionData({state, commit}, { collection, ...args }) {
    if(!collection) collection = state.collection;
    const url = `${collection}/search`;
    return fetch(url, args)
      .then( res => {
        commit(SET_COLLECTION, res);
        //let data = res.data;
        //console.log('get collection data', data);
        //dispatch('Global/setStorage', { collection, data }, { root: true });
        return res;
      });
  },
  removeCollectionData({ commit }, ids) {
    commit(REMOVE_RECORDS, ids);
  },
  getMetaData({ state, commit }){
     return fetch(`${state.collection}/meta`)
      .then( res => {
        commit(SET_META, res);
        return res;
        });
  },
  getReports({state, dispatch, commit}, args) {
    const url = `${state.collection}/reports`;
    return fetch(url, args)
      .then( res => {
        commit(SET_REPORTS, res.data || res);
        return res;
      });
  },
  removeSearch({state, dispatch, commit}, id) {
    const url = `${state.collection}/searches/${id}`;
    return remove(url)
      .then( res => {
        return dispatch('getSearches');
      });
  },
  saveSearch({state, dispatch, commit}, data) {
    const url = `${state.collection}/searches`;
    if(data.uid) {
      return put(url, data)
        .then( res => {
          return dispatch('getSearches');
        });
    } else {
      return post(url, data)
        .then( res => {
          return dispatch('getSearches');
        });
    }
  },
  getSearches({state, dispatch, commit}, args) {
    const url = `${state.collection}/searches`;
    return fetch(url, args)
      .then( res => {
        commit(SET_SEARCHES, res.data || res);
        return res;
      });
  },
  // Currently the search is just used to get the data and return the results
  // and it does not store them in the store
  search({state, dispatch, commit}, args) {
    const url = `${state.collection}/search`;
    return fetch(url, args)
      .then( res => {
        return res;
      });
  },
  // same for the details
  details({state, dispatch, commit}, id) {
    const url = `${state.collection}/details/${id}`;
    return fetch(url)
      .then( res => {
        return res;
      });
  },
  lookup({state, dispatch, commit}, args) {
    const url = `${state.collection}/lookup`;
    return fetch(url, args)
      .then( res => {
        commit(SET_LOOKUP_DATA, res);
        return res;
      });
  },
  preview({ state, dispatch }, { id, params }){
    const { collection } = state;
    return dispatch('PageModals/showModal', {
      component: 'PreviewFactory',
      params: {
        prid: id,
        collection,
        ...params,
      }
    }, { root: true });
  },
  form({ state, dispatch }, params){
    const { collection } = state;
    return dispatch('PageModals/showModal', {
      component: state.form,
      params: {
        collection,
        ...params,
      }
    }, { root: true });
  },
  // The purpose here is to have a generic function that can
  // grab the right inputs, create a form and submit the results of that
  // form to an update function
  // id is the primary key value of the record being updated
  // field is the field we need an input for
  // the url is used to override the current collection url and could be replaced later
  // the callback is to provide some method to notifiy the caller that we are done
  editRecord({ state, dispatch, commit, getters }, {
    url,
    id,
    callback,
    defaults,
    parent,
    parentId,
    field,
    params,
  }) {
    const address = url ? url : getters.api('record', id);

    // check for inputs and data
    // if not we need to load the inputs we may need
    //console.log('collection: edit record (ui)', state.record, address, url, defaults);
    fetch(address).then(res => {
      commit(SET_INPUTS, res.inputs);
      commit(SET_RECORD_DATA, res.data);
    });
    // open the form
    const { collection } = state;
    //commit(state.form, { id, collection, callback, defaults, parent, parentId, field }, { root: true });
    dispatch('PageModals/showModal', {
      component: state.form,
      params: {
        id,
        collection, // where collection is the store name
        callback,
        defaults,
        parent,
        parentId,
        field,
        ...params,
      }
    }, { root: true });
  },
  editField({ state, dispatch, commit, getters }, {
    url,
    id,
    callback,
    defaults,
    parent,
    parentId,
    field,
    params,
  }) {
    const address = url ? url : getters.api('record', id);

    // check for inputs and data
    // if not we need to load the inputs we may need
    //console.log('collection: edit record (ui)', state.record, address, url, defaults);
    fetch(address).then(res => {
      commit(SET_INPUTS, res.inputs);
      commit(SET_RECORD_DATA, res.data);
    });
    // open the form
    const { collection } = state;
    //commit(state.form, { id, collection, callback, defaults, parent, parentId, field }, { root: true });
    dispatch('PageModals/showModal', {
      component: 'CollectionForm',
      params: {
        id,
        collection, // where collection is the store name
        callback,
        defaults,
        parent,
        parentId,
        field,
        ...params,
      }
    }, { root: true });
  },
  postMethod({ state, commit }, { method, data }) {
    const record = state.record || state.collection;
    const url = `${record}/${method}`;
    return post(url, data);
  },
  getEditHistory({ state, commit }, { url, id }) {
    const record = state.record || state.collection;
    const address = url || `${record}/audit/${id}`;
    return fetch(address);
  },
  getHistory({ state, commit }, { url, id }) {
    const record = state.record || state.collection;
    const address = url || `${record}/${id}/history`;
    return fetch(address);
  },
  getRecord({ dispatch, state, commit }, { url, id }) {
    const record = state.record || state.collection;
    const address = url || `${record}/details/${id}`;
    return fetch(address).then((res) => {
      commit(UPDATE_RECORD, res);
      let label = res.label || 'record';
      dispatch(SUBMIT_MESSAGE, {
        type: 'record-loaded',
        params: {
          label: `Retrieved ${label}`,
          collection: state.collection,
          url,
          id,
        },
        active: true
      }, {root: true});
      return res; // changed this from true
    });
  },
  getRecordRaw({ state, commit }, { url, id }) {
    const record = state.record || state.collection;
    const address = url || `${record}/record/${id}`;
    //console.log('get raw record data', address, id, url);
    return fetch(address).then((res) => {
      commit(SET_RECORD_DATA, res.data || res);
      commit(SET_INPUTS, res.inputs);
      return res;
    });
  },
  clone({ state, commit }, { id }) {
    const record = state.record || state.collection;
    const address = `${record}/clone/${id}`;
    //console.log('get raw record data', address, id, url);
    return fetch(address).then((res) => {
      return res;
    });
  },
  getInputs({ state, commit }, { url }) {
    const record = state.record || state.collection;
    const address = url || `${record}/record`;
    return fetch(address).then((res) => {
      commit(SET_INPUTS, res.inputs);
      return res;
    });
  },
  /**
   * Save to collection method that does NOT save to the backend yet
   *
   * temporary method for the mobile app that will add to the collection
   * it should also add something to show that it has not been saved to
   * the database yet
   * now that Ive added the local storage part we should add some logic here
   * so that not all collections are using the local storage
   * for now nothing else is using the saveas/save methods so we are fine
   *
   * @param {object} data: record to be saved
   * @returns {}
   */
  saveas({ state, commit, dispatch }, data) {
    const uid = (state.data.length+1) * -1;
    const d = { uid, ...data, isNew: true };
    commit(ADD_RECORD, d);
    //ls.setStorage(state.collection, d);
    // go throught the global store to save data
    dispatch('Global/setStorage', {
      collection: state.collection,
      data: d
    }, { root: true });
    return true;
  },
  /**
   * Update the record and the local store
   * @param {} data: record to be updated
   * @returns {boolean}
   */
  save({ commit, dispatch, state }, data) {
    if(!data.isNew && !data.isSaved){
      data.isUpdated = true;
    }
    const { collection } = state;
    commit(UPDATE_COLLECTION_RECORD, data);
    dispatch('Global/setStorage', { collection, data }, { root: true });
    return true;
  },
  /**
   * remove the record from the local store
   * @param {int} uid : the unique value for the record
   * @returns {boolean}
   */
  remove({ commit, dispatch, state }, uid) {
    const { collection } = state;
    //console.log('collection remove', collection, uid);
    return dispatch('Global/removeStorage', { collection, uid }, { root: true })
      .then( () => {
        commit(REMOVE_RECORD, { id: uid });
        return true;
      });
  },
  /**
   * Extract the record from the collection and return it
   * should extend this to pull the record from the backend if we connected
   * and it doesnt exist?
   * @param {} uid: Unique id of the record
   * @returns {object}
   */
  get({ state }, uid){
    const rcd = state.data.find(d=>d.uid === uid);
    return rcd;
  },
  /**
   * Check for local storage data
   */
  loadFromLocalStorage({ state, commit, dispatch }){
    const { collection } = state;
    return dispatch('Global/getStorage', collection, { root: true })
      .then( d => {
        if(Array.isArray(d)) commit(SET_RECORDS, d);
      });
  },
  saveToBackend({ state, dispatch, commit }, { uid, resource }) {
    // the resource is something like `propertytasks`
    const endpoint = resource || state.collection;
    const rcd = state.data.find(d => d.uid === uid);
    const { isNew, isUpdated, isSaved, ...data } = rcd;
    const address = isNew
          ? `${endpoint}/saveas`
          : `${state.collection}/save`; // if we are just updating

    const label = isNew
          ? `Record added`
          : `Record updated`;

    return post(address, data).then( res => {
      // update the record to show its been saved
      // remove the record and replace with the new one
      dispatch(SUBMIT_MESSAGE, {
        type: state.addedMessageType,
        params: {
          label,
          collection: state.collection
        },
        active: true
      }, {root: true});
      dispatch('remove', uid)
        .then( () => {
          dispatch('save', { ...data, uid: res.id, isSaved: true });
        });
      return res.id;
    });
  },
  addRecord({ dispatch, state, commit}, { url, data }) {
    const collection = state.collection;
    const address = url || `${collection}/saveas`;
    return post(address, data)
      .then( res => {
        let id = res.id;
        dispatch(SUBMIT_MESSAGE, {
          type: state.addedMessageType,
          params: {
            action: 'added',
            label: data.label,
            collection,
            id,
          },
          active: true
        }, {root: true});
        commit(ADD_RECENTLY_ADDED, id);
        // changed this on 4/18/2019 to match the parent version
        // 10/4/2019 - I need more data from this. At least both the
        // uid and the id (or the id and rid
        return res;
      });
  },
  removeRecord({ dispatch, state, commit, getters }, { url, data }) {
    const uri = url || getters.api('remove', data.id);
    return remove(uri, data)
      .then( res => {
      // should we be updating or refreshing here?
      // we have only removed one so Im going to delete it here
      if(data) commit('REMOVE_RECORD', data);
      dispatch(SUBMIT_MESSAGE, {
        type: 'record-deleted',
        params: { label: 'Record deleted' },
        active: true
      }, {root: true});
      return res;
    });
  },
  updateRecord({ dispatch, state, commit, getters}, { url, id, data, callback }) {
    // if we are calling this from a parent store we have passed
    // a url to use, otherwise we can pull one from the collection
    const uri = url ? url : getters.api('put', id);
    const collection = state.collection;
    return put(uri, data).then((res) => {
      if (callback) {
        callback(res);
      } else {
        data.id = id;
        commit(UPDATE_RECORD, res.data || data );
      }
      dispatch(SUBMIT_MESSAGE, {
        type: state.addedMessageType,
        params: {
          action: 'updated',
          label: data.label || 'Record',
          collection,
          id,
        },
        active: true
      }, {root: true});
      return res;
    });
  },
  getTableData({ state, commit }, { table }) {
    const url = `${table}/lookup`;
    return fetch(url)
      .then( data => {
        commit(SET_TABLE_DATA, { table, data });
      });
  },
  link({ state, dispatch }, { id, parent, parentId }) {
    const url = `${parent}/${parentId}/${state.collection}/${id}`;
	  return post(url);
  },
  unlink({ state, dispatch, commit }, { id, parent }) {
    const parents = {
      properties: 'property',
      projects: 'project',
      issues: 'issue',
    };
    const url = `${parent}/${state.collection}?${state.uniqueId}=${id}`;
    const key = parents[parent];
    commit(UPDATE_RECORD, { id, [key]: null });
    return remove(url);
  },
  insertChildItem({ state, dispatch }, { child, data, id }) {
    const { post } = state.map[child];
    const url = post;
    return post(url, data);// .then((res) => {
    //   dispatch('getRecord', { id });
    // });
  },
  updateChildItem({ state, dispatch }, { child, data }) {
    // update a child record and then refresh the parent record
    // requires that urls are in the parent map
    const { put } = state.map[child];
    const url = put.replace(':id', data.id);
    return post(url, data);// .then((res) => {
    //   // data sent back will have the pkey
    //   dispatch('getRecord', { id: res[0].data[state.uniqueId] });
    // });
  },
  removeChildItem({ state, dispatch }, { child, cid, id }) {
    // where child is for the map and cid is to the child
    const { remove } = state.map[child];
    const url = remove.replace(':id', cid);
    return post(url);// .then((res) => {
    //   dispatch('getRecord', { id });
    // });
  },
  addComment({ state, commit, dispatch }, { id, comment }){
    const record = state.record || state.collection;
    const url = `${record}comments/saveas`;
    const pkey = state.uniqueId;
    const params = { [pkey]: id, comment };
    return post(url, params);
  },
  removeComment({ state, dispatch }, { id, pid }){
    const record = state.record || state.collection;
    const url = `${record}comments/remove`;
    return post(url, { id });
  },
  getSearchFilter({ state, commit}) {
    const url = `${state.collection}/filterData`;
    return fetch(url).then( res => {
      commit('SET_FILTERS', res);
    });
  }
};

export default function (options) {
  const state = { ...initialState, ...options };
  const module = {
    namespaced: true,
    state() {
      return state;
    },
    getters,
    mutations,
    actions,
  };
  return module;
}
