import { createApp } from 'vue'
//import { createStore } from 'vuex'

import App from './App.vue'
import router from './router'
import store from './store';
import './registerServiceWorker'

createApp(App)
    .use(router)
    .use(store)
    .mount('#app')



//const app = createApp({ /* your root component */ })
//app.use(store)
