import { createRouter, createWebHistory } from 'vue-router';
import globalRouteGuard from './RouteGuard';

const LoginRoute = {
  path: '/login',
  name: 'login',
  component: require('@acorns-ui/Forms/LoginForm').default,
};

const routes = [
  LoginRoute,
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home')
  },
  {
    path: '/upload',
    name: 'Upload',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "PhotoPointsUpload" */ '../views/PhotoPointsUpload')
  },
  {
    path: '/report',
    name: 'Report',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Report')
  },
  {
    path: '/publish',
    name: 'Publish',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Publish')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(globalRouteGuard);

export default router;
