import { createStore } from 'vuex';

// collections is a function that can add a collection object to the store
// import collection from './collection';
//import data from './testpoints';

// building the photopoints collection locally so
// that we can add some methods to it
import Global from '@acorns-ui/Stores/Global';
import photopoints from './photopoints';
import user from '@acorns-ui/Stores/User';
import PageMessages from '@acorns-ui/Stores/PageMessages';
import loadImage from 'blueimp-load-image';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { get } from '@/utils';
import moment from 'moment';
import { toRaw } from 'vue';
import { fetch, put } from '@/api';


const toDD = (hms) => {
  hms = hms.split(",");
  var dd = (hms[0]*1) + (hms[1]/60*1) + (hms[2]/3600*1);
  return dd;
};

const getDistance = (lat0, lng0, lat1, lng1) => {
  var a = Math.pow(lat1 - lat0, 2);
  var b = Math.pow(lng1 - lng0, 2);
  var c = Math.sqrt(a + b);
  return c;
};

const processImage = (data, file) => {
  var tags= {};
  var keys = ['Exif', 'GPSInfo', 'Interoperability', 'Thumbnail'];
  var url = data.image.toDataURL
      ? data.image.toDataURL('image/jpeg', 1.0)
      : data.image;
  var name = file.name;
  var datetime = file.lastModified;
  var size = file.size;
  var lat, lng, alt, direction;
  var orientation = data.exif ? data.exif[0x0112] : null;
  var height, width;

  if(data.image && data.image.width) {
    height = data.image.height;
    width = data.image.width;
  }

  for (let i = 0; i < keys.length; i++) {
    let exif = data.exif && data.exif.get(keys[i]);
    if (exif) {
      tags[keys[i]] = exif.getAll();
    }
  }
  if(tags.Exif && tags.Exif.DateTimeOriginal) {
    datetime = tags.Exif.DateTimeOriginal;
    //orientation = tags.Exif.
  }
  if(tags.GPSInfo && tags.GPSInfo.GPSLatitude) {
    lat = toDD(tags.GPSInfo.GPSLatitude);
    lng = toDD(tags.GPSInfo.GPSLongitude);
    if(tags.GPSInfo.GPSLongitudeRef == 'W') {
      lng = lng * -1;
    }
    if(tags.GPSInfo.GPSLatitudeRef == 'S') {
      lat = lat * -1;
    }
    direction = tags.GPSInfo.GPSImgDirection;
    alt = tags.GPSInfo.GPSAltitude;
  }

  datetime = moment(datetime, 'YYYY:MM:DD hh:mm:ss').format('YYYY-MM-DD hh:mm:ss');
  //console.log(datetime, m);
 // console.log('process image', name, datetime, size, tags);
  return { name, height, width, url, file, tags, datetime, size, lat, lng, alt, direction, orientation };
  //this.$emit('added', { name, url, file, tags, datetime, size});
  //this.pointer++;
  //this.$emit('progress', Math.round(this.pointer/this.count * 100));
};

export default createStore({
  state() {
	  return {
	    organizationName: '',
      propertyName: '',
      monitorsName: '',
      propertyId: null, // only for paid users
      propertiesList: [],
	    prefix: 'PPT: ',
      pdfsrc: null,
      tray: [], // these are the global pending images
      additionalPhotos: [], // these are exta photos
      matchDistanceThreshold: 0.0005, // how close before we match it??
      matchAngleThreshold: 25, // how close before we match it??
      loadingProperty: false,
      reportTitle: '',
      reportNotes: '',
      progressBarActive: false,
      progressBarMessage: null,
      progressBarCounter: 0,
      progressBarLimit: 0,
      sortPhotoPointLocationsBy: 'label', // lat, lng
      includeTrayImages: false,
      includeReportHeader: true,
      includeMap: true,
      includeDates: true,
      includeReportNotes: true,
      includeEmptyPhotoPoints: true,
      landscapeMap: true,
      includeImagesInDownload: true,
      includePdfInDownload: true,
      includeManifestInDownload: true,
      helpContent: [
        { label: 'Help', content: 'asdfasdf', type: ''},
      ],
	  };
  },
  modules: {
    Global,
    PageMessages,
    user,
    photopoints,
  },
  mutations: {
    UPDATE_FIELD(state, { key, value }) {
      if(typeof(state[key])!=='undefined') {
        state[key] = value;
      }
    },
    LOADING_PROPERTY(state, value) {
      state.loadingProperty = value;
    },
    ADVANCE_PROGRESS_BAR(state) {
      state.progressBarCounter++;
    },
    SET_PROPERTIES_LIST(state, value) {
      if(value && value.data) {
        value = value.data;
      }
      state.propertiesList = value;
    },
    SET_PROGRESS_BAR(state, value) {
      state.progressBarCounter = value;
    },
    ADD_TO_TRAY(state, image ) {
      state.tray.push(image);
    },
    REMOVE_FROM_TRAY(state, idx ) {
      state.tray.splice(idx, 1);
    },
    ADD_TO_ADDITIONAL_PHOTOS(state, image ) {
      state.additionalPhotos.push(image);
    },
    REMOVE_FROM_ADDITIONAL_PHOTOS(state, idx ) {
      //console.log('remove from additional photos', idx, state);
      state.additionalPhotos.splice(idx, 1);
    },
  },
  getters: {
    premium: (state, getters) => getters['user/level']>1,
    publishable: state => !!state.pdfsrc,
    reportable: state => state.photopoints
      && state.photopoints.data
      && state.photopoints.data.length > 0,
    startup: (state, getters) => !getters.reportable
      && !state.tray.length
      && !state.loadingProperty,
    propertyNeedsSaving: (state, getters) => getters.reportable
      && !state.propertyId
      && !!state.propertyName,
    progressPercentComplete: state => {
      var done = state.progressBarCounter || 0;
      var limit = state.progressBarLimit || 0;
      if(state.progressBarActive && limit > 0) {
        return done/limit * 100;
      } else {
        return 0;
      }
    },
    cameraPointsGeojson: state => {
      const features = state.photopoints.data
            .map( p => {
              let geometry = {
                type: 'Point',
                coordinates: [
                  p.lng,
                  p.lat,
                ]
              };
              let label = p.label;
              let uid = p.uid;
              return {
                type: "Feature",
                properties: { uid, label },
                geometry,
              };
            });

      return {
        type: "FeatureCollection",
        label: 'Photo Point Locations',
        editable: true,
        tooltip: true,
        key: 'photopoints',
        type: 'Point', // eslint-disable-line
        features,
      };
    },
    imagesGeojson: state => {
      const features = state.photopoints.data
            .map( d => {
              return d.photopoints
                .filter( i => i.image && i.image.lat && i.image.lng)
                .map( i => {
                  return {
                    type: 'Feature',
                    properties: {
                      uid: i.image.name,
                      label: i.image.name,
                      date: i.image.datetime,
                      direction: i.image.direction,
                    },
                    geometry: {
                      type: 'Point',
                      coordinates: [
                        i.image.lng,
                        i.image.lat,
                      ],
                    }
                  };
                });
            }).flat();
      return {
        type: "FeatureCollection",
        label: 'Image Locations',
        editable: false,
        key: 'images',
        type: 'Point', // eslint-disable-line
        features,
        style: {
          radius: 6,
          fillColor: "#ff7800",
          color: "#ff7800",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8
        },
      };
    }
  },
  actions: {
    load({ commit, dispatch}) {
      return dispatch('Global/getStorage', 'app', { root: true })
        .then(d=> {
          if(d && d.length) {
            for(var i = 0; i<d.length; i++) {
              let key = d[i].uid;
              let value = d[i].data;
              commit('UPDATE_FIELD', { key, value });
            }
          }
        });
    },
    save({ dispatch, state }, { key, value }) {
      // fix the value
      return dispatch('Global/setStorage', {
        collection: 'app',
        data: {
          uid: key,
          data: toRaw(value),
        }
      }, { root: true });
    },
    loadPropertyList({ commit }) {
      fetch('ppr/properties')
        .then( res =>{
          commit('SET_PROPERTIES_LIST', res);
        });
    },
    loadProperty({ dispatch, commit }, id) {
      commit('LOADING_PROPERTY', true);
      return fetch(`ppr/${id}`)
        .then( res =>{
          dispatch('updateField', {
            key: 'propertyName',
            value: res.label
          });
          dispatch('updateField', { key: 'propertyId', value: id });
          return dispatch('photopoints/loadPhotoPoints', res.locations)
            .then( () => commit('LOADING_PROPERTY', false));
        }).catch( err => {
          commit('LOADING_PROPERTY', false);
        });
    },
    saveProperty({ dispatch, state }) {
      // clean up the property (remove any image objects);
      var locations = state.photopoints.data;

      locations = locations.map(p => {
        // remove any image
        var { image, photopoints, ...pl } = p;
        // now go through the photo points
        photopoints = photopoints.map(pp => {
          var { image, ...ppd } = pp;
          return ppd;
        });
        return { ...pl, photopoints };
      });

      const data = {
        id: state.propertyId,
        label: state.propertyName,
        locations,
      };
      put(`ppr/properties`, data)
        .then( res => {
          dispatch('updateField', { key: 'propertyId', value: res.id });
          dispatch('loadPropertyList');
        });
    },
    clearProperty({ dispatch }) {
      dispatch('updateField', { key: 'propertyId', value: null });
      dispatch('updateField', { key: 'propertyName', value: '' });
      dispatch('updateField', { key: 'monitorsName', value: '' });
      dispatch('updateField', { key: 'pdfsrc', value: null });
      dispatch('updateField', { key: 'additionalPhotos', value: [] });
      dispatch('photopoints/clearPhotoPoints');
    },
    updateField({ dispatch, commit, state }, { key, value }) {
      dispatch('save', { key, value });
      commit('UPDATE_FIELD', { key, value });
    },
    startProgressBar({ commit }, { message, limit }) {
      commit('UPDATE_FIELD', { key: 'progressBarCounter', value: 0 });
      commit('UPDATE_FIELD', { key: 'progressBarLimit', value: limit });
      commit('UPDATE_FIELD', { key: 'progressBarMessage', value: message });
      commit('UPDATE_FIELD', { key: 'progressBarActive', value: true });
      return true;
    },
    clearProgressBar({ commit }) {
      setTimeout( () => {
        commit('UPDATE_FIELD', { key: 'progressBarCounter', value: 0 });
        commit('UPDATE_FIELD', { key: 'progressBarLimit', value: 0 });
        commit('UPDATE_FIELD', { key: 'progressBarMessage', value: null });
        commit('UPDATE_FIELD', { key: 'progressBarActive', value: false });
      }, 500);
    },
    downloadPhotoPoints({ state, dispatch, commit, getters }) {
      // collection some info
      var zip = new JSZip();
      var prefix = state.prefix;
      var csv = 'location, photopoint, image, original_name, datetime, lat, lng, alt\n\r';
      var message = 'Downloading photo points';
      var pplCount = getters['photopoints/photoPointLocationsCount'];
      var ppCount = getters['photopoints/photoPointsCount'];
      var ppls = state.photopoints.data;
      var limit = ppCount + 2;
      dispatch('startProgressBar', { message, limit })
        .then( () => {
          // now start the work
          // for each photo point
          // for each image
          for(var i = 0; i < pplCount; i++) {
            let ppl = ppls[i];
            let location = ppl.label;
            // now create a folder for this location and start adding photos
            let folder = zip.folder(location);
            // now loop through the photo points
            for(var j = 0; j < ppl.photopoints.length; j++) {
              let label = get(['photopoints', j, 'label'], ppl);
              let image = get(['photopoints', j, 'image'], ppl);
              if(image) {
                // so something??
                var date = image.datetime;
                var name = `${prefix}${location}${label}_${date}.jpg`;
                name = name.replace(/[;:\- ]+/g, "_");
                csv += `"${location}","${label}","${name}","${image.name}","${image.datetime}",${image.lat},${image.lng},${image.direction},${image.alt}\n\r`;
                folder.file(name, image.file);
              }
              // update the progress
              //commit('ADVANCE_PROGRESS_BAR');
            }
          }
          if(state.includeManifestInDownload) {
            zip.file("manifest.csv", csv);
          }

          if(state.includePdfInDownload && state.pdfsrc) {
            zip.file("report.pdf", state.pdfsrc, { base64: true });
          }

          zip.generateAsync({type:"blob"}, (m) => {
            commit('SET_PROGRESS_BAR', (m.percent * limit)/100.0);
          })
            .then(function (blob) {
              saveAs(blob, `photopoint_report.zip`);
              dispatch('clearProgressBar');
            });
        });
    },
    addTo({ dispatch, state, commit, getters }, { image, threshold }) {
      if(image.lat && state.photopoints.data.length) {
        var distances = state.photopoints.data.map( p => {
          var distance = getDistance(p.lat, p.lng, image.lat, image.lng);
          var uid = p.uid;
          var label = p.label;
          var photopoint = p.photopoints.find( pp => {
            let az = pp.azimuth * 1;
            let dr = image.direction * 1;
            let buffer = state.matchAngleThreshold;
            return dr && az && az >= (dr - buffer) && az <= (dr + buffer);
          });
          // try and find the right angle
          return { uid, distance, label, photopoint};
        });
        var closest = distances.sort((a, b) => b.distance - a.distance).pop();
        if(image && closest.distance < state.matchDistanceThreshold) {
          // now we could try and match the label and/or angle?
          image.closest = closest;
        }
        //console.log(closest);
      }
      //console.log('adding image', image.name);
      if(image.closest) {
        // see if we cant find a match
        if(image.closest.photopoint) {
          return dispatch('photopoints/addPhotoPointImage', {
            id: image.closest.photopoint.uid,
            image,
          });
        } else {
          return dispatch('photopoints/addPhotoPoint', {
            id: image.closest.uid,
            data: {
              description: `Added using ${image.name}`,
              azimuth: image.direction,
              image,
            }
          });
        }
        //
      } else if(image.lat) {
        // create a new one
        return dispatch('photopoints/addPhotoPointLocation', {
         // label: image.name,
          lat: image.lat,
          lng: image.lng,
          description: `Added using the location of ${image.name}`,
          image,
        });
      } else {
        return dispatch('addToTray', image);
      }
    },
    getDistance({ state }) {
    },
    getImage({ state, dispatch }, { source, index }) {
      if(source == 'tray') {
        return dispatch('getTrayImage', { idx: index });
      } else if(source == 'additionalPhotos'){
        return dispatch('getAdditionalPhotoImage', { idx: index });
      } else if(source == 'photopoints'){
        return dispatch('photopoints/getPhotoPointImage', { id: index*1 });
      } else {
        return new Promise( resolve => resolve(false));
      }
    },
    placeImage({ dispatch }, { source, index, image }) {
      if(source == 'tray') {
        dispatch('addToTray', image);
      }
    },
    transferImage({ state, dispatch }, { from, to }) {
      return dispatch('getImage', from)
        .then( image => {
          image.closest = null;
          return dispatch('placeImage', { ...to, image });
        });
    },
    getTrayImage({ state, commit, dispatch }, { idx }) {
      const image = state.tray[idx];
      commit('REMOVE_FROM_TRAY', idx);
      dispatch('save', { key: 'tray', value: state.tray });
      return image;
    },
    addToTray({ commit, dispatch, state }, image) {
      commit('ADD_TO_TRAY', image);
      return dispatch('save', { key: 'tray', value: state.tray });
    },
    addToAdditionalPhotos({ commit, dispatch, state }, image) {
      commit('ADD_TO_ADDITIONAL_PHOTOS', image);
      return dispatch('save', { key: 'additionalPhotos', value: state.additionalPhotos });
    },
    getAdditionalPhotoImage({ state, commit, dispatch }, { idx }) {
      const image = state.additionalPhotos[idx];
      commit('REMOVE_FROM_ADDITIONAL_PHOTOS', idx);
      dispatch('save', { key: 'additionalPhotos', value: state.additionalPhotos });
      return image;
    },
    loadFileImage({ state }, { file, options }) {
      //console.log('loading image', file, options);
      return loadImage(
        file,
        {
          ...options,
          canvas: true,
          meta: true,
        },
      ).then( data => {
        return processImage(data, file);
      }).catch( err => {
        console.log('error loading image', err);
      });
    }
  },
});
