//import data from './testpoints2';
//import data from './p167.json';

import {
  initialState as baseState,
  getters as baseGetters,
  actions as baseActions,
  mutations as baseMutations,
} from './collection';
//} from '@acorns-ui/Stores/Collections/Mixins/collection';

var photoPointIdCounter = 0;
var photoPointLocationIdCounter = 0;

var nextPhotoPointId = () => {
  photoPointIdCounter++;
  return photoPointIdCounter * -1;
};

var nextPhotoPointLocationId = () => {
  photoPointLocationIdCounter++;
  return photoPointLocationIdCounter * -1;
};

const initialState = {
  ...baseState,
  uniqueId: 'id',
  record: 'photopoints',
  collection: 'photopoints',
  lastPhotoPointLocationId: null,
  lastPhotoPointId: null,
  rowSortingFunctions: {
    label: dir => {
      const adj = dir == 'asc' ? 1 : -1;
      return (a, b) => {
        let al = a.label.toLocaleLowerCase();
        let bl = b.label.toLocaleLowerCase();
        // does it start with a number?
        let an = parseInt(al);
        let bn = parseInt(bl);
        al = an ? an.toFixed(0).padStart(5,0) : al;
        bl = bn ? bn.toFixed(0).padStart(5,0) : bl;
        return al > bl ? 1*adj : -1*adj;
      };
    },
  },
// data, // test data
};

const letters = 'ABCDEFGHIJKLMNOPQRSTUV'.split('');
const angles = [0, 90, 180, 270];
//import { toRaw } from 'vue';

const getters = {
  ...baseGetters,
  photoPointIds: state => {
    return state.data.map( l => {
      return l.photopoints.map( p => {
        return p.uid;
      });
    }).flat();
  },
  // nextPhotoPointId: (state, getters) => {
  //   var mx = getters.photoPointIds.reduce((a,b) => {
  //     a = parseInt(a);
  //     b = parseInt(b);
  //     if(a < 0) a = a*-1;
  //     if(b < 0) a = b*-1;
  //     return Math.max(a,b);
  //   }, 0);
  //   return (mx + 1) * -1;
  // },
  photoPointLocationIds: state => {
    return state.data.map( l => l.uid);
  },
  // nextPhotoPointLocationId: (state, getters) => {
  //   var mx = getters.photoPointLocationIds.reduce((a,b) => {
  //     a = parseInt(a);
  //     b = parseInt(b);
  //     if(a < 0) a = a*-1;
  //     if(b < 0) a = b*-1;
  //     return Math.max(a,b);
  //   }, 0);
  //   return (mx + 1) * -1;
  // },
  photoPointLocationsCount: state => {
    return state.data.length;
  },
  photoPointsCount: (state, getters) => {
    const ids = getters['photoPointIds'];
    return ids.length;
  },
  getPhotoPointLocationIdByPhotoPointId: (state) => (id) => {
    var ppl, uid;
    for(var i = 0; i<state.data.length; i++) {
      // and now the photo points
      ppl = state.data[i];
      for(var j = 0; j<ppl.photopoints.length; j++) {
        // look for the id
        if(ppl.photopoints[j].uid === id) {
          return ppl.uid;
        }
      }
    }
    return uid;
  },
  newPhotoPoint: (state, getters) => (id, data) => {
    var ppl = getters.getPhotoPointLocationById(id);
    var n = ppl ? ppl.photopoints.length : 0;
    if(!data.uid) {
      //data.uid = (getters.photoPointIds.length + 1) * -1;
      data.uid = nextPhotoPointId();
      //console.log('adding new photo point', data.uid);
    }
    data.added = true;
    if(!data.label) {
      data.label = `${letters[n]}`;
    }
    if(!data.azimuth) {
      data.azimuth = angles[n];
    }
    return data;
  },
  getPhotoPointLocationById: (state) => (id) => {
    var ppl = state.data.find(d=>d.uid === id);
    return ppl;
  },
  getPhotoPointById: (state) => (id) => {
    var ppl, pp;
    for(var i = 0; i<state.data.length; i++) {
      // and now the photo points
      ppl = state.data[i];
      for(var j = 0; j<ppl.photopoints.length; j++) {
        // look for the id
        if(ppl.photopoints[j].uid === id) {
          pp = ppl.photopoints[j];
          break;
        }
      }
    }
    return pp;
  },
  center: ( state) => {
    var y = 45.525, x = -122.625;
    if(state.data.length) {
      x = state.data
        .map(d => d.lng)
        .reduce((a,v,i)=>(a*i+v)/(i+1));
      y = state.data
        .map(d => d.lat)
        .reduce((a,v,i)=>(a*i+v)/(i+1));
    }
    return [y, x];
  },
};

const mutations = {
  ...baseMutations,
  UPDATE_PHOTOPOINT_FIELD(state, { id, key, value }) {
    // will need to find the photo point id
    // then add the image
    //console.log('update photo point field', id, key, value);
    var pp;
    for(var i = 0; i<state.data.length; i++) {
      // and now the photo points
      var ppl = state.data[i];
      for(var j = 0; j<ppl.photopoints.length; j++) {
        // look for the id
        pp = ppl.photopoints[j];
        if(pp.uid === id) {
          console.log('updating photo point field', key, value);

          ppl.photopoints[j] = { ...pp, [key]: value };
          state.data.splice(i, 1, ppl);
          // we found it so we are done
          break;
        }
      }
    }
  },
  UPDATE_PHOTOPOINT_RECORD(state, value) {
    // will need to find the photo point id
    console.log(value);
    const { uid, ...data } = value;
    // then add the image
    //console.log('update photo point field', uid, data);
    var pp;
    for(var i = 0; i<state.data.length; i++) {
      // and now the photo points
      var ppl = state.data[i];
      for(var j = 0; j<ppl.photopoints.length; j++) {
        // look for the id
        pp = ppl.photopoints[j];
        if(pp.uid === uid) {
          // saving in the object for later
          // so that the action can use it
          value.plid = ppl.uid;
          //console.log('updating photo point record', pp, data);
          // save notes to the image
          if(data.notes && pp.image) {
            pp.image.notes = data.notes;
            ppl.photopoints[j] = { ...pp };
          } else {
            ppl.photopoints[j] = { ...pp, ...data };
          }
          state.data.splice(i, 1, ppl);
          // we found it so we are done
          break;
        }
      }
    }
  },
  ADD_PHOTOPOINT_LOCATION(state, data) {
    state.data.push(data);
  },
  REMOVE_PHOTOPOINT_LOCATION(state, id) {
    const idx = state.data.findIndex(d=>d.uid === id);
    if(idx>=0) {
      state.data.splice(idx,1);
    }
  },
  ADD_PHOTOPOINT(state, { id, data }) {
    // id is the photo point location id
    const idx = state.data.findIndex(d=>d.uid === id);
    if(idx>=0) {
      let ppl = state.data[idx];
      ppl.photopoints.push(data);
      state.data.splice(idx, 1, ppl);
    }
  },
  REMOVE_PHOTOPOINT(state, id) {
    var pp;
    for(var i = 0; i<state.data.length; i++) {
      // and now the photo points
      var ppl = state.data[i];
      for(var j = 0; j<ppl.photopoints.length; j++) {
        // look for the id
        pp = ppl.photopoints[j];
        if(pp.uid === id) {
          ppl.photopoints.splice(j, 1);
          state.data.splice(i, 1, ppl);
          // we found it so we are done
          break;
        }
      }
    }
  },
  ADD_PHOTOPOINT_IMAGE(state, data) {
    // will need to find the photo point id
    // then add the image
    var { id, image } = data;
    var pp;
    for(var i = 0; i<state.data.length; i++) {
      // and now the photo points
      var ppl = state.data[i];
      for(var j = 0; j<ppl.photopoints.length; j++) {
        // look for the id
        pp = ppl.photopoints[j];
        if(pp.uid === id) {
          data.plid = ppl.uid; // saved for later
          ppl.photopoints[j] = { ...pp, image };
          state.data.splice(i, 1, ppl);
          // we found it so we are done
          break;
        }
      }
    }
  },
  REMOVE_PHOTOPOINT_IMAGE(state, id) {
    // will need to find the photo point id
    // then add the image
    var pp;
    for(var i = 0; i<state.data.length; i++) {
      // and now the photo points
      var ppl = state.data[i];
      for(var j = 0; j<ppl.photopoints.length; j++) {
        // look for the id
        pp = ppl.photopoints[j];
        if(pp.uid === id) {
          pp.image = null;
          ppl.photopoints[j] = pp;
          state.data.splice(i, 1, ppl);
          // we found it so we are done
          break;
        }
      }
    }
  },
};

const actions = {
  ...baseActions,
  updateCounters({ getters }) {
    // get the max value for each counter
    if(getters.photoPointIds.length) {
      photoPointIdCounter = Math.min(...getters.photoPointIds) * -1;
    }
    if(getters.photoPointLocationIds.length) {
      photoPointLocationIdCounter = Math.min(...getters.photoPointLocationIds) * -1;
    }
    console.log('updating counters', getters.photoPointIds,  photoPointIdCounter,getters.photoPointLocationIds, photoPointLocationIdCounter);
  },
  clearPhotoPoints({ commit, dispatch }) {
    const collection = 'photopoints';
    commit('SET_RECORDS', []);
    return dispatch('Global/clearStorage', { collection }, { root: true });
  },
  loadPhotoPoints({ dispatch }, data) {
    return dispatch('clearPhotoPoints')
      .then( () => {
        return Promise.all(
          data.map( d => {
            return dispatch('addPhotoPointLocation', d);
          })
        );
      });
  },
  addPhotoPointLocation({ commit, getters, dispatch }, data) {
    var image = data.image;
    data.image = null;
    if(!data.uid) {
      //data.uid = (photoPointLocationIds.length + 1) * -1;
      data.uid = nextPhotoPointLocationId();
    }
    if(!data.photopoints) {
      data.photopoints = [];
    }
    if(!data.label) {
      data.label = `${getters.photoPointLocationIds.length+1}`;
    }
    if(!data.lat) {
      data.lat = 45.25;
    }
    if(!data.lng) {
      data.lng = -122.625;
    }

    commit('ADD_PHOTOPOINT_LOCATION', data);
    // photo point method will save
    if(!data.photopoints.length) {
      return dispatch('addPhotoPoint', {
        id: data.uid,
        data: {
          description: image ? `Added using ${image.name}` : 'Added as initial point',
          azimuth: image ? image.direction : 0,
          image,
        }
      });
    } else {
      return dispatch('savePhotoPointLocation', data.uid);
    }
  },
  savePhotoPointLocation({ getters, dispatch }, id) {
    var data;
    if(typeof(id) === 'object' && id.uid) {
      data = id;
    } else {
      data = getters.getPhotoPointLocationById(id);
    }
    if(data) {
      // remove all the proxies
      //console.log(toRaw(data));
      data = { ...data };
      data.photopoints = data.photopoints.map(p => {
        let image = { ...p.image };
        image.tags = { };
        if(image.closest) {
          image.closest = null;
        }
        return { ...p, image };
      });
      //window.ppl = data;
      // fix data
     // console.log('saving photo point location', id, data);
      return dispatch('Global/setStorage', {
        collection: 'photopoints',
        data,
      }, { root: true });
    } else {
      return new Promise( resolve => resolve(false));
    }
  },
  removePhotoPoint({ commit, dispatch, getters }, id) {
    var plid = getters.getPhotoPointLocationIdByPhotoPointId(id);
    // then remove it
    commit('REMOVE_PHOTOPOINT', id);
    return dispatch('savePhotoPointLocation', plid);
  },
  addPhotoPoint({ commit, getters, dispatch }, { id, data }) {
    // data expects name, direction, uid
    //console.log('adding photo point', ppl);
    data = getters.newPhotoPoint(id, data);
    commit('ADD_PHOTOPOINT', { id, data });
    dispatch('savePhotoPointLocation', id);
    return data;
  },
  addPhotoPointImage({ commit, dispatch }, data) {
    commit('ADD_PHOTOPOINT_IMAGE', data);
    if(data.plid) {
      dispatch('savePhotoPointLocation', data.plid);
    }
  },
  updatePhotoPointRecord({ commit, dispatch }, data) {
    // find the photo point to update
    commit('UPDATE_PHOTOPOINT_RECORD', data);
    // now i can save the photo point location
    if(data.plid) {
      dispatch('savePhotoPointLocation', data.plid);
    }
  },
  updateCollectionRecordField({ commit, dispatch }, data) {
    // find the photo point to update
    commit('UPDATE_COLLECTION_RECORD_FIELD', data);
    // now i can save the photo point location
    if(data.uid) {
      dispatch('savePhotoPointLocation', data.uid);
    }
  },
  getPhotoPointImage({ state, commit, dispatch }, { id }) {
    var pp, image, ppl, plid;
    for(var i = 0; i<state.data.length; i++) {
      // and now the photo points
      ppl = state.data[i];
      for(var j = 0; j<ppl.photopoints.length; j++) {
        // look for the id
        pp = ppl.photopoints[j];
        if(pp.uid === id) {
          plid = ppl.uid;
          image  = pp.image;
          break;
        }
      }
      if(plid) break;
    }
    if(image) {
      commit('REMOVE_PHOTOPOINT_IMAGE', id);
      dispatch('savePhotoPointLocation', plid);
    }
    return image;
  },
  removePhotoPointLocation({ state, commit, dispatch }, id) {
    // check for photo point images and transfer them
      const ppl = state.data.find(d => d.uid === id);
      if(ppl) {
        return Promise.all(
          ppl.photopoints
            .filter(d=>d.image)
            .map(pp => {
              let from = { source: 'photopoints', index: pp.uid };
              let to = { source: 'tray' };
              // remove the location information from the image
              dispatch('transferImage', { from, to }, { root: true });
            })
        ).then(() => {
          commit('REMOVE_PHOTOPOINT_LOCATION', id);
          dispatch('Global/removeStorage', {
            collection: 'photopoints',
            uid: id,
          }, { root: true });
          return true;
        });
      } else {
        commit('REMOVE_PHOTOPOINT_LOCATION', id);
        dispatch('Global/removeStorage', {
          collection: 'photopoints',
          uid: id,
        }, { root: true });
        return true;
      }
  }
};

const module = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
