<template>
<AppLayout
  id="nav"
  loading-message="Setting everything up"
  :loaded="ready"
  :progress="progressPercentComplete">

  <template v-slot:navbar>
    <!-- This div needs to be replaced with the navbar but -->
    <!-- it has not been updated to vue 3 yet -->
    <Navbar
      :include-global="false"
      :use-google-login="true"
      @avatar-clicked="logout"
      @authorized="handleAuthorized"
      @not-authorized="handleNotAuthorized">

      <template v-slot:logo>
        <IconAcorn
          @click="$router.push('/')"/>
      </template>

      <template
        v-if="loggedIn && !startup"
        v-slot:main>
        <ul class="breadcrumbs">
          <li>
            <a
              class="restart"
              @click="clearProperty">
              Clear & Restart
            </a>
          </li>
          <li>
            <router-link
              class="finished"
              to="/upload">
              Add photo points & images
            </router-link>
          </li>
          <li>
            <router-link
              class="active"
              :class="{ disabled: !reportable }"
              to="/report">
              Refine & print report
            </router-link>
          </li>
          <li>
            <router-link
              class="ready"
              :class="{ disabled: !publishable }"
              to="/publish">
              Download report & images
            </router-link>
          </li>
        </ul>
      </template>

    </Navbar>
  </template>

  <router-view
    :key="viewKey"/>

  <PageMessages/>


</AppLayout>
</template>

<script>
import AppLayout from '@acorns-ui/Layouts/AppLayout';
import Navbar from '@acorns-ui/Navigation/Navbar';
import IconAcorn from '@acorns-ui/Icons/IconAcorn';
import PageMessages from '@acorns-ui/PageMessages';
import { mapGetters, mapState } from 'vuex';
//import IconLink from '@acorns-ui/Icons/IconLink';

//import { get } from '@/utils';

export default {
  name: 'App',
  components: {
    AppLayout,
    IconAcorn,
    Navbar,
    PageMessages,
   // IconLink,
  },
  data(){
    return {
      ready: false,
      viewKey: 1,
    }
  },
  created() {
    this.initialize();
  },
  watch: {
    loggedIn(a) {
      if(a) {
        this.$store.dispatch('loadPropertyList');
      }
    },
  },
  computed: {
    ...mapGetters([
      'progressPercentComplete',
      'publishable',
      'reportable',
      'startup',
      'premium',
    ]),
    ...mapState([
      'progressBarLimit',
      'progressBarCounter',
      'user',
    ]),
    ...mapState('user', [
      'loggedIn',
    ]),
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout')
        .then( () => this.$router.push('/'));
    },
    initialize(){
      // make sure the local database is created and then
      // create collections and check for local data
      this.$store.dispatch('Global/createLocalDatabase', {
        name: 'PP1',
        version: 5,
        schema: [
          {
            name: 'app',
          },
          {
            name: 'tray',
          },
          {
            name: 'photopoints',
          }
        ]
      }).then( () => {
        this.$store.dispatch('load');
        this.$store.dispatch('loadPropertyList');
        return this.$store.dispatch('photopoints/loadFromLocalStorage')
          .then( () => {
            this.$store.dispatch('photopoints/updateCounters');
          })
      }).then(() => {
        this.ready = true;
      })
    },
    clearProperty() {
      return this.$store.dispatch('clearProperty')
        .then( () => {
          this.$store.dispatch('updateField', {
            key: 'tray',
            value: [],
          });
          this.$store.dispatch('updateField', {
            key: 'pdfsrc',
            value: null,
          });
          this.viewKey += 1; // this will make sure that map section gets reset
        });
    },
    handleAuthorized(email) {
      this.$store.dispatch('PageMessages/submitMessage', {
        label: 'Authorized',
        message: `${email} is authorized. Please use the links in the navigation bar to start your report`,
        timer: 2000,
      });
    },
    handleNotAuthorized(email) {
      this.$store.dispatch('PageMessages/submitMessage', {
        label: 'Not Authorized',
        message: `${email} is not authorized, please try a different email address`,
        timer: 2000,
      });
    }
  }
};
</script>


<style lang="scss">
@import './styles/variables';
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400&display=swap');
@import '~@acorns-ui/Styles/acorns';
@import '~@acorns-ui/Styles/print';
$breadcrumbs_color: #72925b;

html, body {
  margin: 0;
  width: 100vw;
  height: 100vh;
}
#app {
  // font-family: 'Avenir', Helvetica, Arial, sans-serif;
  //font-family: 'Varela Round', Helvetica, Arial, sans-serif;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  //background-color: $page-color;
  height: 100%;

  #nav a {
    font-weight: bold;
    //color: #2c3e50;
  }

  #nav a.router-link-exact-active {
  //  color: #42b983;
  }

  .clickable {
    cursor: pointer;
    &:hover {
      color: $link-color;
    }
  }

  a.disabled {
    pointer-events: none;
    color: rgba(0,0,0,0.25) !important;
  }

  #app-main {
    background-color: $white;
  }


  .breadcrumbs {
    list-style: none;
    overflow: hidden;
    margin: 0;
    padding: 0 3rem 0 0;

    li {
      float: left;
    }
    li a {
      background: $breadcrumbs_color;
      color: white;
      text-decoration: none;
      padding: 0.75rem 0 0.75rem 3rem;
      position: relative;
      display: block;
      float: left;
    }
    li:first-child a {
      padding-left: 1rem;
    }

    li a::after {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
      border-bottom: 50px solid transparent;
      border-left: 30px solid $breadcrumbs_color;
      position: absolute;
      top: 50%;
      margin-top: -50px;
      left: 100%;
      z-index: 2;
    }
    li a::before {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 30px solid white;
      position: absolute;
      top: 50%;
      margin-top: -50px;
      margin-left: 1px;
      left: 100%;
      z-index: 1;
    }

    li a.router-link-exact-active {
      background: darken($breadcrumbs_color, 10);
    }

    li a.router-link-exact-active:after {
      border-left-color: darken($breadcrumbs_color, 10) !important;
    }

    li a.disabled {
      background: lighten($breadcrumbs_color, 20);
    }

    li a.disabled:after {
      border-left-color: lighten($breadcrumbs_color, 20) !important;
    }

    li a:hover {
      background: darken($breadcrumbs_color, 15);
    }
    li a:hover:after {
      border-left-color: darken($breadcrumbs_color, 15) !important;
    }

    li a.restart {
      cursor: pointer;
      background-color: $red;
      &:after {
        border-left-color: $red;
      }
      &:hover {
        background-color: darken($red, 10);
        &:after {
        border-left-color: darken($red, 10) !important;
        }
      }
    }
  }

}
</style>

<!-- <style> -->
<!-- #app { -->
<!--   font-family: Avenir, Helvetica, Arial, sans-serif; -->
<!--   -webkit-font-smoothing: antialiased; -->
<!--   -moz-osx-font-smoothing: grayscale; -->
<!--   color: #2c3e50; -->
<!--   width: 100%; -->
<!-- } -->

<!-- #nav { -->
<!--   padding: 30px; -->
<!-- } -->

<!-- #nav a { -->
<!--   font-weight: bold; -->
<!--   color: #2c3e50; -->
<!-- } -->

<!-- #nav a.router-link-exact-active { -->
<!--   color: #42b983; -->
<!-- } -->

<!-- .row { -->
<!--     display: flex; -->
<!--     flex-direction: row; -->
<!--     align-items: center; -->
<!-- } -->

<!-- </style> -->
