import store from '@/store';

export default function globalRouteGuard(to, from, next) {
  const loggedIn = store.state.user.loggedIn;
  const requiresAuth = to.meta.requiresAuth;
  // If we are not logged in we should check to see
  // if we have already been validated
  console.log('Router guard', to.fullPath, loggedIn, requiresAuth);
  if(loggedIn) {
    to; from;
    next();
  } else if(requiresAuth) {
    // check for cookie
    // we could just be refreshing and so we should check for a cookie
    // user/check should only return true or false, no errors
    store.dispatch('user/check')
      .then( li => {
        if(li) {
          next();
        } else {
          next({
            path: '/login',
            query: { url: to.fullPath }
          });
        }
      });
  } else {
    // still could check to see if we are ready
    // no need to wait for validation
    store.dispatch('user/check');
    next();
  }

}
